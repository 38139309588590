import { fetchAuthSession } from "aws-amplify/auth";
import { API_SERVER } from "../config/constants";


export const updateSocialAccount = async (orgSkuuid, social_account_sk, payload) => {
  const session = await fetchAuthSession();
  const [_, social_network, social_account_id] = social_account_sk.split("#");
  const response = await fetch(
    API_SERVER + 
    `/organization/${orgSkuuid}/social-accounts/${social_network.toLowerCase()}/${social_account_id}`,
    {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${session.tokens.idToken.toString()}`,
      },
      body: JSON.stringify(payload),
    }
  );

  const responseData = await response.json();
  if (!response.ok)
    throw new Error(responseData.message || "Network response was not ok");
  return responseData;
};
