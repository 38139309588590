import React, { useContext, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { useOutletContext } from "react-router-dom";

import { ReactComponent as EyeOffIcon } from "../../assets/icons/eye_off_icon.svg";
import { ReactComponent as EyeOnIcon } from "../../assets/icons/eye_on_icon.svg";
import {
    getOrganizationById,
    createCheckoutSession,
    fetchProductPrice,
    fetchSystemConfig,
} from "../../services";
import * as NotificationService from "../../utils/notificationService";
import CreditUsageRate from "./CreditUsageRate";
import { calculateTotalToCharge } from "../../utils/payment";
import { AuthContext } from "../../AuthProvider";
import { extractPkSk } from "../../utils/common";
import SystemConfigSks from "../../data/enums/systemConfigSks";

export default function ProductDisplay() {
    const auth = useContext(AuthContext);
    const { orgId } = useOutletContext();
    const defaultCreditQuantityValues = [5000, 10000, 20000, 50000, 100000];
    const [creditsQuantitySelected, setCreditsQuantitySelected] = useState(defaultCreditQuantityValues[0]);
    const [customAmount, setCustomAmount] = useState("");
    const [customCredits, setCustomCredits] = useState("");
    const [showCredits, setShowCredits] = useState(true);
    const { skuuid: orgSkuuid } = extractPkSk(orgId);

    const organizationQuery = useQuery({
        queryKey: ["organization", orgSkuuid],
        queryFn: () => {
            if (!orgId || !auth.user) return null;
            return getOrganizationById(orgId);
        },
        enabled: !!orgSkuuid,
    });
    
    const productPriceQuery = useQuery({
        queryKey: ["product-price"],
        queryFn: () => fetchProductPrice(),
        staleTime: 10 * 1000,
        onError: (error) => {
            NotificationService.notifyError(
                `Failed to fetch product price: ${error.message}`
            );
        },
    });

    const cpFeePercentageQuery = useQuery({
        queryKey: ["system-config", SystemConfigSks.APP_FEE_PERCENTAGE],
        queryFn: () => fetchSystemConfig(SystemConfigSks.APP_FEE_PERCENTAGE),
        staleTime: 10 * 1000,
        onError: (error) => {
            NotificationService.notifyError(
                `Failed to fetch system config: ${error.message}`
            );
        },
    });


    if (productPriceQuery.isLoading || cpFeePercentageQuery.isLoading) {
        return (
            <div className="flex items-center justify-center h-screen">
                <p className="text-lg font-medium">Loading...</p>
            </div>
        );
    }

    const productPrice = productPriceQuery.data.unit_amount / 100;
    const netAmount = (productPrice * creditsQuantitySelected);
    const totalToCharge = calculateTotalToCharge(netAmount, cpFeePercentageQuery.data.config.app_fee_percentage);

    const organizationCreditsAmount = organizationQuery.data.paid_credits + organizationQuery.data.free_credits;
    const organizationMoneyAmount = organizationCreditsAmount * productPrice;

    const handleSave = async () => {
        try {
            const payload = {
                total_to_charge: totalToCharge,
                organization_id: orgId,
            };
            if (customCredits && customCredits < 1000.0) {
                throw new Error("Amount of credits to buy must be at least 1000")
            }
            const session = await createCheckoutSession(payload);
            window.location.href = session.url;
        } catch (error) {
            NotificationService.notifyError(
                `Failed to execute payment: ${error.message}`
            );
        }
    };

    const handleCustomAmountChange = (e) => {
        const value = parseFloat(e.target.value);
        if (!isNaN(value) && value > 0) {
            setCustomAmount(value);
            const customCredits = parseFloat((value / productPrice).toFixed(2));
            setCreditsQuantitySelected(customCredits);
            setCustomCredits(customCredits);
        } else {
            setCustomAmount("");
            setCreditsQuantitySelected(defaultCreditQuantityValues[0]);
        }
    };

    const toggleShowCredits = () => {
        setShowCredits((prev) => !prev);
    };

    return (
        <section className="mx-auto p-6 bg-white rounded-lg border flex gap-8 my-[10px] mx-[10px]">
            <div className="w-[65%]">
                <h2 className="text-[24px] font-semibold text-gray-800 mb-10">Manage your Credits</h2>
                <h3 className="text-[22px] font-semibold text-gray-800 mb-2">Pay as you go</h3>
                <p className="text-[18px] font-normal text-[#51606F] mb-2 flex items-center gap-2">
                    Credit balance
                    <button onClick={toggleShowCredits} className="focus:outline-none">
                        {showCredits ? <EyeOffIcon/> : <EyeOnIcon/>}
                    </button>
                </p>
                <div className="text-2xl font-bold bg-gradient-to-r from-[#9F58F7] to-[#447EF6] bg-clip-text text-transparent w-fit">
                    {showCredits ? `${organizationCreditsAmount.toFixed(0)} Credits` : `$${organizationMoneyAmount.toFixed(2)}`}
                </div>
                <div className="grid grid-cols-3 gap-3 mt-4">
                    <div className={`w-[150px] h-[70px] px-2 py-3 border rounded-lg text-sm font-medium flex flex-col items-center justify-center ${customAmount ? "border-main-blue text-main-blue" : "border-gray-300"}`}>
                        <div className="relative w-full">
                            <span className="absolute top-1/2 transform -translate-y-1/2 text-lg font-semibold text-gray-500">$</span>
                            <input
                                type="number"
                                className="w-full pl-5 text-center bg-transparent border-none outline-none text-lg font-semibold [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none border-transparent focus:border-transparent focus:ring-0"
                                placeholder="Add Amount"
                                value={customAmount}
                                onChange={handleCustomAmountChange}
                            />
                        </div>
                        <span className="text-xs text-gray-500">{customCredits} Credits</span>
                    </div>
                    {defaultCreditQuantityValues.map((quantity) => {
                        const isQuantitySelected = !customAmount
                            ? quantity === creditsQuantitySelected : false;
                        return (
                            <button
                                key={quantity}
                                className={`w-[150px] h-[70px] px-2 py-3 border rounded-lg text-sm font-medium flex flex-col items-center justify-center ${isQuantitySelected ? "border-main-blue text-main-blue" : "border-gray-300"}`}
                                onClick={() => {
                                    setCreditsQuantitySelected(quantity);
                                    setCustomAmount("");
                                }}
                            >
                                <span className="font-semibold">${quantity * productPrice}</span>
                                <span className={`text-xs ${isQuantitySelected ? "text-main-blue" : "text-gray-500"}`}>{quantity} Credits</span>
                            </button>
                        );
                    })}
                </div>
                <button
                    type="button"
                    className="mt-6 px-4 py-2 font-bold text-white bg-gradient-to-r from-[#447EF6] to-[#9F58F7] rounded h-[56px]"
                    onClick={handleSave}
                >
                    Add Credit Balance
                </button>
                <p className="pt-2 text-[#454545]">
                    <span className="text-red-500">*</span>Please note operational charges may apply
                </p>
            </div>
            <div className="w-[37%]">
                <CreditUsageRate />
            </div>
        </section>
    );
}
