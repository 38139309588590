import { fetchAuthSession } from "aws-amplify/auth";
import { API_SERVER } from "../config/constants";


export const createCheckoutSession = async (payload) => {
  try {
    const session = await fetchAuthSession();
    const response = await fetch(`${API_SERVER}/payments/create-checkout-session`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${session.tokens.idToken.toString()}`,
      },
      body: JSON.stringify(payload),
    });
    const responseData = await response.json();
    if (!response.ok)
      throw new Error(responseData.message || "Network response was not ok");
    return responseData;
  } catch (error) {
    console.error("Failed to createCheckoutSession:", error);
    throw error;
  }
};

export const fetchCheckoutSession = async (checkoutSessionId) => {
  try {
    const session = await fetchAuthSession();
    const response = await fetch(`${API_SERVER}/payments/retrieve-checkout-session/${checkoutSessionId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${session.tokens.idToken.toString()}`,
      }
    });
    const responseData = await response.json();
    if (!response.ok)
      throw new Error(responseData.message || "Network response was not ok");
    return responseData;
  } catch (error) {
    console.error("Failed to createCheckoutSession:", error);
    throw error;
  }
};

export const fetchProductPrice = async () => {
  try {
    const session = await fetchAuthSession();
    const response = await fetch(`${API_SERVER}/payments/retrieve-product-price`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${session.tokens.idToken.toString()}`,
      }
    });
    const responseData = await response.json();
    if (!response.ok)
      throw new Error(responseData.message || "Network response was not ok");
    return responseData;
  } catch (error) {
    console.error("Failed to fetchProductPrice:", error);
    throw error;
  }
};