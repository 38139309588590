import { fetchAuthSession } from "aws-amplify/auth";
import { API_SERVER } from "../config/constants";


export const processImage = async (campaignSkuuid, postSkuuid, payload) => {
    try {
        const session = await fetchAuthSession();
        const baseUrl = `${API_SERVER}/campaigns/${campaignSkuuid}/posts/${postSkuuid}/image`;
        const response = await fetch(baseUrl, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${session.tokens.idToken.toString()}`,
            },
            body: JSON.stringify({
                prompt: payload.prompt,
                organization_id: payload.organizationUuid,
                owner_email: payload.ownerUserEmail
            }),
        });
        const responseData = await response.json();
        if (!response.ok)
            throw new Error(responseData.message || "Network response was not ok");
        return responseData;
    } catch (error) {
        console.error("Failed to process image:", error);
        throw error;
    }
};

export const enhanceImage = async (campaignId) => {
    try {
        const session = await fetchAuthSession();
        const response = await fetch(
            `${API_SERVER}/campaigns/${campaignId}/image/enhance`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${session.tokens.idToken.toString()}`,
                },
                body: JSON.stringify({}),
            }
        );
        const responseData = await response.json();
        if (!response.ok)
            throw new Error(responseData.error || "Network response was not ok");
        return responseData;
    } catch (error) {
        console.error("Failed to enhanceImage:", error);
        throw error;
    }
};