class VisibilityType {
    static PUBLIC = "PUBLIC";
    static PRIVATE = "PRIVATE";
  
    static toReadable(type) {
      switch (type) {
        case this.PUBLIC:
          return "Public";
        case this.PRIVATE:
          return "Private";
        default:
          return null;
      }
    }
  
    static getTypeFromString(typeString) {
      switch (typeString) {
        case "PUBLIC":
          return this.PUBLIC;
        case "PRIVATE":
          return this.PRIVATE;
        default:
          return null;
      }
    }
  
    static getReadableFromString(typeString) {
      return VisibilityType.toReadable(VisibilityType.getTypeFromString(typeString));
    }
  
    static getRoleOptions() {
      return [this.PUBLIC, this.PRIVATE];
    }
  }
  
  export default VisibilityType;