const STRIPE_FLAT_FEE = 0.30;
const STRIPE_FEE_PERCENTAGE = 2.9;

export function calculateTotalToCharge(netAmount, cpFeePercentage) {
    const profitAmount = netAmount * (cpFeePercentage / 100);
    const totalToCharge = (netAmount + profitAmount + STRIPE_FLAT_FEE) / (1 - (STRIPE_FEE_PERCENTAGE / 100));
    return parseFloat(totalToCharge.toFixed(2));
}

export function calculateNetAmount(totalToCharge, cpFeePercentage) {
    const netAfterStripeFees = totalToCharge * (1 - (STRIPE_FEE_PERCENTAGE / 100)) - STRIPE_FLAT_FEE;
    const netAmount = netAfterStripeFees / (1 + (cpFeePercentage / 100));
    return parseFloat(netAmount.toFixed(2));
}