import React from 'react';
import { useQuery } from "@tanstack/react-query";

import {
    fetchBaseLinePrice,
} from "../../services";
import * as NotificationService from "../../utils/notificationService";
import { ReactComponent as ImageIcon } from "../../assets/icons/image_icon_rounded.svg";
import { ReactComponent as LeftMessageIcon } from "../../assets/icons/left_message_icon_rounded.svg";
import { ReactComponent as RightMessageIcon } from "../../assets/icons/right_message_icon_rounded.svg";

export default function CreditUsageRate() {
    const { data: baseLineData, isLoading: baseLineIsLoading, isError: baseLineIsError } = useQuery({
        queryKey: ["baseline-price"],
        queryFn: () => fetchBaseLinePrice(),
        staleTime: 10 * 1000,
        onError: (error) => {
            NotificationService.notifyError(
                `Failed to fetch baseline prices: ${error.message}`
            );
        },
    });

    if (baseLineIsLoading) {
        return (
            <div className="flex items-center justify-center h-screen">
                <p className="text-lg font-medium">Loading...</p>
            </div>
        );
    }
    if (baseLineIsError) {
        return <></>;
    }
    const creditsUsageItems = [
        {
            bgClass: "bg-[#E8F0FF]",
            icon: <ImageIcon />,
            label: "Image Generation",
            description: "Each post image you generate",
            value: `${baseLineData.price_image} credits per generated image`
        },
        {
            bgClass: "bg-[#FAF5FF]",
            icon: <LeftMessageIcon />,
            label: "Chat Input",
            description: "The text you send to our chat",
            value: `${baseLineData.price_open_ai_token_input * 100} credits per 75 words sent to the chat`
        },
        {
            bgClass: "bg-[#F0FDF4]",
            icon: <RightMessageIcon />,
            label: "Chat Output",
            description: "AI chat responses",
            value: `${baseLineData.price_open_ai_token_output * 100} credits per 75 words in chat responses`
        },
        {
            bgClass: "bg-[#FFF4E5]",
            icon: <ImageIcon />,
            label: "Post generation",
            description: "An estimate of generating a full post",
            value: `${baseLineData.price_complete_post || 5} credits per full post generation (aprox.)`
        },
    ];

    return (
        <div className='rounded-lg border p-4'>
            <h3 className="text-lg font-semibold text-gray-700 mb-3">Credit Usage Rate</h3>
            <div className="space-y-3">
                {creditsUsageItems.map((creditUsage, index) => (
                    <div key={index} className={`flex items-center justify-between p-3 ${creditUsage.bgClass} rounded-md min-h-[76px]`}>
                        <div className="flex items-center gap-3">
                            {creditUsage.icon}
                            <div>
                                <span className="font-bold text-[18px] text-[#121212] block">{creditUsage.label}</span>
                                <span className="font-medium text-[14px] text-[#121212]">{creditUsage.description}</span>
                            </div>
                        </div>
                        <div className="px-3 py-1 bg-white border border-gray-300 rounded-md text-[14px] font-semibold w-[175px] min-h-[36px] flex justify-center items-center">
                            {creditUsage.value}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}
