import { useQuery } from "@tanstack/react-query";

import LocalStorageKeys from "../data/enums/localStorageKeys";
import { getMyOrganizations } from "../services";
import useLocalStorage from "../hooks/LocalStorage/useLocalStorage";
import { useContext } from "react";
import { AuthContext } from "../AuthProvider";

export default function SyncOrganizationStorage() {
    const [organizationId, setOrganizationId] = useLocalStorage(LocalStorageKeys.ORGANIZATION_ID_KEY, null);
    const auth = useContext(AuthContext);

  const user = `${auth.user.given_name}.${auth.user.family_name}`;
  const myOrganizationsQuery = useQuery({
    queryKey: ["myOrganizations"],
    queryFn: () => getMyOrganizations(user),
    staleTime: 60 * 1000,
  });

  const organizations = myOrganizationsQuery.data || [];

  const validOrganization =
    organizations.find((org) => org.id === organizationId) || organizations[0];

    const validOrgId  = validOrganization.id

    if (organizationId !==  validOrgId) {
        setOrganizationId(validOrgId);
    }
}
