import { fetchAuthSession } from "aws-amplify/auth";
import { API_SERVER } from "../config/constants";


export const fetchSystemConfig = async (system_config_sk) => {
  try {
    const session = await fetchAuthSession();
    const response = await fetch(`${API_SERVER}/system-config/${system_config_sk}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${session.tokens.idToken.toString()}`,
      }
    });
    const responseData = await response.json();
    if (!response.ok)
      throw new Error(responseData.error || "Network response was not ok");
    return responseData;
  } catch (error) {
    console.error("Failed to fetchSystemConfig:", error);
    throw error;
  }
};