import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useContext, useState } from "react";
import { FaTrashAlt } from "react-icons/fa";
import {
  createRole,
  deleteRole,
  getMyOrganizations,
  getOrganizationById,
  updateRole,
} from "../../services";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import AddCollaboratorModal from "./AddCollaboratorModal";
import * as NotificationService from "../../utils/notificationService";
import DeleteConfirmationModal from "../common/modals/DeleteConfirmationModal";
import RoleType from "../../data/enums/roleType";
import { defaultCollaborator } from "../../config/constants";
import { AuthContext } from "../../AuthProvider";
import LocalStorageKeys from "../../data/enums/localStorageKeys";
import useLocalStorage from "../../hooks/LocalStorage/useLocalStorage";
import { use } from "react";
import SyncOrganizationStorage from "../../utils/sync-organization-storage";
import { extractPkSk } from "../../utils/common";

export default function UserManagement() {
  const QueryClient = useQueryClient();
  const { orgId } = useOutletContext();
  const { organizationskuuid } = useParams();
  const [dropdownOpenId, setDropdownOpenId] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [collaboratorSelectedToDelete, setCollaboratorSelectedToDelete] =
    useState(null);
  const [openDeleteRoleModal, setOpenDeleteRoleModal] = useState(false);
  const [collaborators, setCollaborators] = useState([defaultCollaborator]);
  const [isValidCollaboratorsData, setIsValidCollaboratorsData] =
    useState(true);
  const [storeOrgId, setOrgId, removeStorageOrgId] = useLocalStorage(
    LocalStorageKeys.ORGANIZATION_ID_KEY,
    null
  );
  const navigate = useNavigate();
  const auth = useContext(AuthContext);
  let newRole = {};
  const createRoleMutation = useMutation({
    mutationFn: async (updatedData) => await createRole(updatedData),
    onSuccess: () => {
      QueryClient.invalidateQueries(["organization", orgId]);
      NotificationService.notifySuccess("Organization updated successfully.");
    },
    onError: () => {
      NotificationService.notifyError("Failed to update the organization.");
    },
  });

  const updateRoleMutation = useMutation({
    mutationFn: async (updatedData) => await updateRole(updatedData),
    onSuccess: (roleCreated) => {
      QueryClient.invalidateQueries(["organization", orgId]);
      NotificationService.notifySuccess("Organization updated successfully.");
    },
    onError: () => {
      NotificationService.notifyError("Failed to update the organization.");
    },
  });

  const deleteRoleMutation = useMutation({
    mutationFn: async (dataToDelete) => await deleteRole(dataToDelete),

    onSuccess: async () => {
      if (auth.user.email === collaboratorSelectedToDelete.email) {
        const user = `${auth.user.given_name}.${auth.user.family_name}`;
        removeStorageOrgId();
        const myOrgs = await getMyOrganizations(user);
        setOrgId(myOrgs[0].id);
        const { skuuid } = extractPkSk(myOrgs[0].id);
        navigate(`/mkt/organizations/${skuuid}/projects`);
      }
      QueryClient.invalidateQueries(["myOrganizations"]);

      QueryClient.invalidateQueries(["organization", orgId]);

      NotificationService.notifySuccess("Organization updated successfully.");
    },

    onError: () => {
      NotificationService.notifyError("Failed to update the organization.");
    },
  });

  const myOrganizationsQuery = useQuery({
    queryKey: ["myOrganization", organizationskuuid],
    queryFn: () => getOrganizationById(orgId),
    staleTime: 60 * 1000,
  });

  let members = myOrganizationsQuery.data.members;

  const handleRemoveCollaborator = (email) => {
    const dataToDelete = {
      assigned_to: email,
      entity_id: orgId,
    };

    deleteRoleMutation.mutate(dataToDelete);

    setOpenDeleteRoleModal(false);
  };

  const handleStatusChange = (status, userEmail, userName) => {
    newRole = {
      role: status,
      assigned_to: userEmail,
      entity_id: orgId,
      name: userName,
    };
    updateRoleMutation.mutate(newRole);
    setDropdownOpenId(null);
  };

  const toggleDropdown = (userId) => {
    setDropdownOpenId(dropdownOpenId === userId ? null : userId);
  };
  const memberEmails = members.map((member) => member.email);
  members = members.map((member) => ({
    id: member.email,
    name: member.name?.trim() || "Unknown",
    email: member.email,
    role: member.role,
    initials: member.name
      .split(" ")
      .map((n) => n[0])
      .join(""),
  }));

  const handleCollaboratorChange = (index, field, value) => {
    const updatedCollaborators = [...collaborators];
    updatedCollaborators[index] = {
      ...updatedCollaborators[index],
      [field]: value,
    };
    setCollaborators(updatedCollaborators);
  };

  const handleCancel = () => {
    setOpenModal(false);
    setCollaborators([defaultCollaborator]);
  };

  const handleSubmitNewCollaborator = () => {
    collaborators.forEach((collaborator) => {
      const newCollaborator = {
        role: collaborator.role,
        name: collaborator.name,
        assigned_to: collaborator.email,
        entity_id: orgId,
      };

      createRoleMutation.mutate(newCollaborator);
    });
    handleCancel();
  };

  const getAvailableRoles = (canAddOwner) => {
    let availableRoles = ["Admin", "Member"];
    if (canAddOwner) {
      availableRoles.push("Owner");
    }
    return availableRoles;
  };
  const canAddOwner = members.some(
    (member) =>
      member.role === RoleType.OWNER && member.email === auth.user.email
  );

  const ownerCount = members.filter(
    (member) => member.role === RoleType.OWNER
  ).length;
  return (
    <div className="p-6 mx-auto">
      <div className="flex justify-between items-start mb-8">
        <div>
          <h1 className="text-xl font-semibold text-gray-900">
            Users Management
          </h1>
          <p className="text-sm text-gray-500">
            Manage organization members and their roles
          </p>
        </div>
        <button
          onClick={() => setOpenModal(true)}
          className="px-4 py-2 bg-blue-500 text-white rounded-lg text-sm hover:bg-blue-600 transition-colors"
        >
          Add Collaborator
        </button>
      </div>

      <div className="space-y-4">
        {members.map((member) => (
          <div
            key={member.id}
            className="flex items-center justify-between p-4 bg-white rounded-lg border border-gray-200"
          >
            <div className="flex items-center gap-3">
              <div className="w-10 h-10 rounded-full bg-blue-100 flex items-center justify-center">
                <span className="text-sm font-medium text-blue-600">
                  {member.initials}
                </span>
              </div>
              <div>
                <h3 className="text-sm font-medium text-gray-900">
                  {member.name}
                </h3>
                <p className="text-sm text-gray-500">{member.email}</p>
              </div>
            </div>

            <div className="flex items-center gap-3">
              <div className="relative">
                <button
                  type="button"
                  disabled={ownerCount === 1 && member.role === RoleType.OWNER}
                  onClick={() => toggleDropdown(member.id)}
                  className={`w-30 min-w-[100px] px-3 py-2 border rounded-md shadow-sm focus:outline-none 
              focus:ring-2 bg-white flex items-center justify-center gap-2 text-black
              ${
                ownerCount === 1 && member.role === RoleType.OWNER
                  ? "opacity-50 cursor-not-allowed border-gray-300 bg-gray-100 "
                  : "focus:ring-blue-500 focus:border-blue-500 border-gray-300"
              }`}
                >
                  <div className="flex items-center gap-2">
                    <div className="w-2 h-2 rounded-full bg-blue-500"></div>
                    <span className="truncate">{member.role}</span>
                  </div>
                </button>

                {dropdownOpenId === member.id && (
                  <div className="absolute w-full mt-1 bg-white border border-gray-300 rounded-md shadow-lg z-10">
                    {getAvailableRoles(canAddOwner).map((statusOption) => (
                      <button
                        key={statusOption}
                        type="button"
                        className="w-4 px-3 py-2 text-left hover:bg-gray-100 flex items-center gap-2"
                        onClick={() =>
                          handleStatusChange(
                            statusOption,
                            member.email,
                            member.name
                          )
                        }
                      >
                        <div className="w-2 h-2 rounded-full bg-blue-500"></div>
                        {statusOption}
                      </button>
                    ))}
                  </div>
                )}
              </div>

              <button
                onClick={() => {
                  setOpenDeleteRoleModal(true);
                  setCollaboratorSelectedToDelete(member);
                }}
                disabled={ownerCount === 1 && member.role === RoleType.OWNER}
                className={`px-3 py-1 text-sm text-red-500 hover:text-red-700 
                  ${
                    ownerCount === 1 && member.role === RoleType.OWNER
                      ? "opacity-50 cursor-not-allowed"
                      : "hover:text-gray-900"
                  }`}
              >
                <FaTrashAlt />
              </button>
              <DeleteConfirmationModal
                entityName={`Collaborator ${collaboratorSelectedToDelete?.name}`}
                showModal={openDeleteRoleModal}
                setOpenModal={setOpenDeleteRoleModal}
                confirmDelete={() =>
                  handleRemoveCollaborator(collaboratorSelectedToDelete?.email)
                }
              />
            </div>
          </div>
        ))}
      </div>
      <AddCollaboratorModal
        currentCollaboratorEmails={memberEmails}
        openModal={openModal}
        handleCancel={handleCancel}
        collaborators={collaborators}
        setCollaborators={setCollaborators}
        handleCollaboratorChange={handleCollaboratorChange}
        createCollaboratorHandler={handleSubmitNewCollaborator}
        setIsValidCollaboratorsData={setIsValidCollaboratorsData}
        isValidCollaboratorsData={isValidCollaboratorsData}
      />
    </div>
  );
}
