import React from 'react';
import { useSearchParams, useNavigate, useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import * as NotificationService from "../utils/notificationService";
import {
    fetchCheckoutSession,
} from "../services";
import paymentIcon from '../assets/icons/payment_successful.png';
import Button from "../components/common/Button";

export default function ConfirmCheckoutPage() {
    const { organizationskuuid } = useParams();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const checkoutSessionId = searchParams.get("session_id");

    const checkoutSessionQuery = useQuery({
        queryKey: ["checkout_session", checkoutSessionId],
        queryFn: () => fetchCheckoutSession(checkoutSessionId),
        staleTime: 10 * 1000,
        enabled: !!checkoutSessionId,
        onError: (error) => {
            NotificationService.notifyError(
                `Failed to fetch payment info: ${error.message}`
            );
        },
    });

    if (!checkoutSessionId) {
        navigate("/");
        return null;
    }

    if (checkoutSessionQuery.isLoading) {
        return (
            <div className="flex items-center justify-center h-screen">
                <p className="text-lg font-medium">Loading...</p>
            </div>
        );
    }

    if (checkoutSessionQuery.isError) {
        return (
            <div className="flex items-center justify-center h-screen">
                <p className="text-lg font-medium text-red-500">Error: {checkoutSessionQuery.error.message || 'Failed to load payment info.'}</p>
            </div>
        );
    }

    const redirectToBilling = () => {
        navigate(`/mkt/organizations/${organizationskuuid}/settings/billing`, { replace: true });
    };

    return (
        <div className="flex flex-col items-center justify-center h-screen bg-gray-100 p-6 overflow-hidden">
            <h1 className="text-4xl font-bold text-gray-800 mb-10">Payment Confirmation</h1>

            <div className="bg-white shadow-lg rounded-lg p-8 w-full max-w-md text-center border">
                <img
                    src={paymentIcon}
                    alt="Payment Successful"
                    className="w-36 h-36 mx-auto mb-6"
                />
                <h2 className="text-2xl font-bold text-gray-800 mb-4">Payment Successful</h2>
                <p className="text-gray-700 text-lg mb-2">
                    Thank you for your payment, <span className="font-semibold">{checkoutSessionQuery.data.checkout_session.customer_details.name}</span>!
                </p>
                <p className="text-gray-700 text-lg mb-4">
                    You paid <span className="font-semibold">${(checkoutSessionQuery.data.checkout_session.amount_total / 100).toFixed(2)} {checkoutSessionQuery.data.checkout_session.currency.toUpperCase()}</span>.
                </p>

                {checkoutSessionQuery.data.invoice?.hosted_invoice_url && (
                    <div>
                        <a
                        href={checkoutSessionQuery.data.invoice.hosted_invoice_url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-500 hover:underline font-medium text-lg mb-6 inline-block"
                    >
                        Download Invoice
                    </a>
                    </div>
                )}

                <Button onClick={redirectToBilling}>
                    Go to Billing Page
                </Button>
            </div>
        </div>
    );
}