import { fetchAuthSession } from "aws-amplify/auth";
import { API_SERVER } from "../config/constants";
import { extractPkSk } from "../utils/common";

export const fetchPost = async (campaignSkuuid, postSkuuid) => {
  const session = await fetchAuthSession();
  const response = await fetch(`${API_SERVER}/campaign/${campaignSkuuid}/posts/${postSkuuid}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${session.tokens.idToken.toString()}`,
    },
  });

  const responseData = await response.json();
  if (!response.ok)
    throw new Error(responseData.error || "Network response was not ok");
  return responseData;
};

export const createPost = async (post) => {
  try {
    const session = await fetchAuthSession();
    const response = await fetch(`${API_SERVER}/posts`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${session.tokens.idToken.toString()}`,
      },
      body: JSON.stringify(post),
    });
    const responseData = await response.json();
    if (!response.ok)
      throw new Error(responseData.error || "Network response was not ok");
    return responseData;
  } catch (error) {
    console.error("Failed to createPost:", error);
    throw error;
  }
};

export const fetchPostMessages = async (campaignSkuuid, postSkuuid) => {
  try {
    const session = await fetchAuthSession();
    const response = await fetch(
      `${API_SERVER}/campaigns/${campaignSkuuid}/posts/${postSkuuid}/assistant/messages`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${session.tokens.idToken.toString()}`,
        },
      }
    );
    const responseData = await response.json();
    if (!response.ok) throw new Error(responseData.error || "Network response was not ok");
    return responseData.messages;
  } catch (error) {
    console.error("Failed to fetchPostMessages:", error);
    throw error;
  }
};

export const updatePost = async (postId, data) => {
  try {
    const { pkuuid: postPkuuid, skuuid: postSkuuid } = extractPkSk(postId);
    const session = await fetchAuthSession();
    const response = await fetch(`${API_SERVER}/campaign/${postPkuuid}/posts/${postSkuuid}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${session.tokens.idToken.toString()}`,
      },
      body: JSON.stringify(data),
    });
    const responseData = await response.json();
    if (!response.ok)
      throw new Error(responseData.error || "Network response was not ok");
    return responseData;
  } catch (error) {
    console.error("Failed to updatePost:", error);
    throw error;
  }
};

export const publishPost = async (payload) => {
  try {
    const session = await fetchAuthSession();
    const response = await fetch(
      `${API_SERVER}/posts/publish`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${session.tokens.idToken.toString()}`,
        },
        body: JSON.stringify(payload),
      }
    );
    const responseData = await response.json();
    if (!response.ok) throw new Error(responseData.message || responseData.details || "Network response was not ok");
    return responseData;
  } catch (error) {
    console.error("Failed to publishPost:", error);
    const formattedError = error.message ? error.message : JSON.stringify(error);
    throw formattedError;
  }
};

export const schedulePost = async (payload) => {
  try {
    const session = await fetchAuthSession();
    const response = await fetch(`${API_SERVER}/post/schedule`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${session.tokens.idToken.toString()}`,
      },
      body: JSON.stringify(payload),
    });
    const responseData = await response.json();
    if (!response.ok)
      throw new Error(responseData.message || "Network response was not ok");
    return responseData;
  } catch (error) {
    console.error("Failed to schedulePost:", error);
    throw error;
  }
};

export const deletePost = async (postId) => {
  try {
    const session = await fetchAuthSession();
    const { pkuuid: campaignSkuuid, skuuid: postSkuuid } = extractPkSk(postId);
    const response = await fetch(`${API_SERVER}/campaign/${campaignSkuuid}/posts/${postSkuuid}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${session.tokens.idToken.toString()}`,
      },
    });
    const responseData = await response.json();
    if (!response.ok)
      throw new Error(responseData.error || "Network response was not ok");
    return responseData;
  } catch (error) {
    console.error("Failed to deletePost:", error);
    throw error;
  }
};

