import React, { useEffect, useState } from "react";
import { Modal } from "flowbite-react";
import { useParams } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";

import { validateInput } from "../../utils/validations";
import * as NotificationService from "../../utils/notificationService";
import Dropdown from "../common/forms/Dropdown";
import VisibilityType from "../../data/enums/visibilityType";
import ActionButtonsGroup from "../common/buttons/ActionButtonsGrup";
import CustomInput from "../common/forms/CustomInput";
import { updateSocialAccount } from "../../services";


const EditSocialAccountModal = ({ openModal, setOpenModal, socialAccount }) => {
    const queryClient = useQueryClient();
    const { organizationskuuid } = useParams();
    const initialErrors = {
        account_name: "",
        visibility: "",
        same_data: true,
    };
    const [errors, setErrors] = useState(initialErrors);
    const initialAccountToUpdate = {
        account_name: socialAccount.account_name,
        visibility: socialAccount.visibility,
    };
    const [accountToUpdate, setAccountToUpdate] = useState(initialAccountToUpdate);

    const handleInputChange = (setter, field, errorMessage) => (e) => {
        const value = e.target.value;
        setter((prevState) => ({ ...prevState, [field]: value }));
        validateInput(value, field, setErrors, errorMessage);
    };

    const updateSocialAccountHandler = async () => {
        try {
            const payload = {
                ...(accountToUpdate.account_name !== socialAccount.account_name && 
                    { account_name: accountToUpdate.account_name }
                ),
                ...(accountToUpdate.visibility !== socialAccount.visibility && 
                    { visibility: accountToUpdate.visibility }
                ),
            };
            const accountUpdated = await updateSocialAccount(
                organizationskuuid,
                socialAccount.social_account_id,
                payload,
            );
            queryClient.setQueryData(
                ["user-social-accounts", organizationskuuid],
                (oldData) => {
                    if (!oldData) return [accountUpdated];

                    return oldData.map((account) =>
                        account.id === socialAccount.id ? accountUpdated : account
                    );
                }
            );
            setOpenModal(false);
            NotificationService.notifySuccess(`Target platform updated successfully`);
        } catch (error) {
            NotificationService.notifyError(`Failed to update Target platform: ${error.message}`);
        }
    };

    const handleCancel = () => {
        setOpenModal(false);
        setAccountToUpdate(initialAccountToUpdate);
        setErrors(initialErrors);
    };

    const validateAccountName = () => {
        validateInput(
            accountToUpdate.account_name,
            "account_name",
            setErrors,
            "Account name is required."
        );
    };

    const validateAllInputs = () => {
        validateAccountName();
    };

    useEffect(() => {
        const isSameData = socialAccount.account_name === accountToUpdate.account_name
            && socialAccount.visibility === accountToUpdate.visibility;

        setErrors((prevState) => ({
            ...prevState,
            same_data: isSameData,
        }))
    }, [socialAccount, accountToUpdate]);

    return (
        <Modal show={openModal} onClose={handleCancel}>
            <Modal.Header className="text-center">Update Account</Modal.Header>
            <Modal.Body>
                <div className="space-y-6">
                    <div className="relative">
                        <CustomInput
                            id={`account_name`}
                            type="text"
                            label="Account name *"
                            value={accountToUpdate.account_name}
                            error={errors.account_name}
                            onChange={handleInputChange(
                                setAccountToUpdate,
                                "account_name",
                                "Account name is required."
                            )}
                            onBlur={validateAccountName}
                        />
                    </div>
                    <Dropdown
                        selectedOption={accountToUpdate.visibility}
                        options={VisibilityType.getRoleOptions()}
                        onSelect={(option) =>
                            setAccountToUpdate((prevState) => ({
                                ...prevState,
                                visibility: option,
                            }))
                        }
                        labelExtractor={(option) => VisibilityType.toReadable(option)}
                        title="Visibility"
                        defaultTitle="Visibility *"
                    />
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className="w-full flex-row justify-end space-x-3">
                    <ActionButtonsGroup
                        saveLabel="Update"
                        saveDisabled={errors.account_name || errors.same_data}
                        onSave={updateSocialAccountHandler}
                        onCancel={handleCancel}
                        onSaveDisabled={validateAllInputs}
                    />
                </div>
            </Modal.Footer>
        </Modal>
    );
};

export default EditSocialAccountModal;
